<template>
    <v-container fluid fill-height v-bind:style="{ 'background-image': 'url(' + bgImg + ')' }" class="bg-container">
        <v-container fill-height>
            <v-flex xs12 sm12 md12>
                <v-card class="elevation-0" style="background: transparent">
                    <v-row>
                        <v-col cols="12" sm="2"></v-col>
                        <v-col cols="12" sm="4" class="sign-primary-hd-col">
                            <transition name="fade"><h1 class="sign-primary-hd" v-if="showItems">SUPER<br/>SCI</h1></transition>
                        </v-col>
                        <v-col cols="12" sm="6"></v-col>
                    </v-row>
                    <v-row class="signin-form-row">
                        <v-col cols="12" sm="4"></v-col>
                        <v-col cols="12" sm="4" class="text-center signin-form">
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <p>USERNAME</p>
                                <v-text-field
                                    name="username"
                                    label="Username"
                                    
                                    type="text"
                                    v-model="username"
                                    :rules="usernameRules"
                                    required rounded outlined filled solo flat
                                    class="elevation-0"
                                    color="#000000"> 
                                </v-text-field>
                                <p>PASSWORD</p>
                                <v-text-field
                                    
                                    name="password"
                                    label="Password"
                                    v-model="password"
                                    :rules="passwordRules"
                                    color="#000000"
                                    rounded outlined required filled solo flat
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    @keyup.enter.native="submit"
                                    @click:append="showPassword = !showPassword">
                                </v-text-field>
                                 <v-btn type="button" color="#66D73D" rounded elevation="0" style="padding: 0px 70px" x-large :disabled="signinActionInProgress" @click="submit"><v-progress-circular
                                    indeterminate
                                    color="#000"
                                    v-if="signinActionInProgress"
                                    class="progressLoader"
                                ></v-progress-circular><span v-if="!signinActionInProgress">LOGIN</span></v-btn>
                                <v-row style="padding-top:5px">
                                    <!-- <v-col cols="12" sm="6" class="text-left"><router-link to="/forgot-username"> Forgot Username?</router-link></v-col>
                                    <v-col cols="12" sm="6" class="text-right"><router-link to="/forgot-password"> Forgot Password?</router-link></v-col> -->
                                </v-row>
                            </v-form>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <transition name="fade"><v-img :src="twoB" v-if="showItems"></v-img></transition>
                        </v-col>
                        <v-col cols="12" sm="1"></v-col>
                    </v-row>
                </v-card>
            </v-flex>
        </v-container>
    </v-container>
</template>
<script>
import background from '@/assets/white-science-icon-background.jpg';
import twoB from '@/assets/2B-with-Beaker.png';
export default {
    name: 'SignIn',
    data() {
        return {
            bgImg: background,
            twoB: twoB,
            username: '',
            password: '',
            valid: false,
            usernameRules: [
                v => !!v || 'Username is required'
            ],
            passwordRules: [
                v => !!v || 'Password is required'
            ],
            showPassword: false,
            showItems: false,
        };
    },
    methods: {
        submit() {
            var _this = this;
             _this.$store.state.signinActionInProgress = true;
            setTimeout(function(){
                if (_this.$refs.form.validate()) {
                    _this.$store.dispatch('userLogin', {
                        username: _this.username,
                        password: _this.password,
                    });
                }
                else{
                    _this.$store.state.signinActionInProgress = false;
                }
            }, 3000);
        },
        /* googleSignin(){
            Auth.federatedSignIn({provider: 'Google'});
        } */
    },
    computed: {
        responseMsg(){
            return this.$store.state.responseMsg;
        },
        ResponseClass(){
            return this.$store.state.ResponseClass;
        },
        signinActionInProgress(){
            return this.$store.state.signinActionInProgress;
        },
        /* disabledLoginBtn(){
            return this.$store.state.disabledLogin;
        },
        
        
        signinActionBtnDisabled(){
            return this.$store.state.signinActionBtnDisabled;
        },
        rememberMeCheckbox:{
            set(value){
                this.$store.state.rememberMeCheckbox = value
            },
            get(){
                return this.$store.state.rememberMeCheckbox
            }
        } */
    },
    watch: {
    },
    mounted(){
        /*if(this.$store.state.user != null){
            this.$router.push({ path: '/' });
        }*/
        var _this = this; setTimeout(function(){_this.showItems = true;}, 3000);
    },
    created() {
        
    }
};
</script>
<style scoped>
.sign-primary-hd{
    font-family: 'Lomo', sans-serif;
    font-size: 50px;
    letter-spacing: 5px;
}
.signin-form p{
    margin: 0px;
    font-weight: 500;
    font-size: 20px;
}
.v-input__control .v-input__slot{
    margin-bottom: 5px;
}
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: 1s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}
.sign-primary-hd-col{
    padding-bottom: 0px;
    min-height: 150px;
}
@media screen and (min-width: 768px) {
    .signin-form-row, .signin-form-row .signin-form{
        margin-top: 0px;
        padding-top: 0px;
    }
}
</style>